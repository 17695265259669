<template>
  <div v-if="!loading" class="fill-height d-flex flex-column mb-n5">
    <v-img
      class="photo"
      :src="product.photo_size.medium"
      :style="{ marginTop: `-${$vuetify.application.top}px` }"
      :aspect-ratio="8 / 6"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-sheet class="product-details grow">
      <v-container class="py-7">
        <div class="name">{{ product.item_name }}</div>
        <div class="mb-6" v-html="product.item_description" />
        <div class="weight mb-6" v-if="weight">
          {{ weight }} {{ product.unit || 'гр.' }}
        </div>

        <div
          class="mb-6 secondary--text"
          v-if="product.prices && product.prices.length"
        >
          <v-row
            v-for="(variant, i) in product.prices"
            :key="i"
            align="center"
            class="variant"
            :class="{ 'dark--text': i == priceIndex }"
            @click="changeVariant(i)"
            v-ripple="{ class: 'secondary--text' }"
          >
            <v-col cols="auto">
              <div class="check-icon" :class="{ active: i == priceIndex }">
                <v-icon v-if="i == priceIndex" color="#fff">{{
                  icons.mdiCheck
                }}</v-icon>
              </div>
            </v-col>
            <v-col>{{ variant.size }}</v-col>
            <v-col cols="auto font-weight-black text-right">
              <div v-if="variant.discount_price" class="error--text old-price">
                {{ variant.price }} {{ $store.getters['app/currency'] }}
              </div>
              {{ variant.discount_price || variant.price }}
              {{ $store.getters['app/currency'] }}
            </v-col>
          </v-row>
        </div>

        <div class="qty-box d-flex align-center mb-8">
          <v-btn
            outlined
            x-large
            class="qty-button"
            color="secondary"
            @click="minus"
          >
            <v-icon color="dark">{{ icons.mdiMinus }}</v-icon>
          </v-btn>
          <div class="grow text-center font-weight-bold">{{ qty }}</div>
          <v-btn
            outlined
            x-large
            class="qty-button"
            color="secondary"
            @click="plus"
          >
            <v-icon color="dark">{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </div>
        <nutritional-value :bju="bju" />
        <product-addons v-if="product.addon_item" :addons="addons" />
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mdiCheck, mdiMinus, mdiPlus } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import actionButtonMixin from '@/mixins/actionButton'
import NutritionalValue from '@/components/NutritionalValue'
import ProductAddons from '@/components/ProductAddons'

export default {
  name: 'ProductDetail',

  mixins: [actionButtonMixin],

  components: {
    NutritionalValue,
    ProductAddons,
  },

  data: () => ({
    loading: true,
    priceIndex: 0,
    qty: 1,
    icons: {
      mdiCheck,
      mdiMinus,
      mdiPlus,
    },
    cartAddons: {},
  }),

  computed: {
    weight() {
      return this.prices?.size_weight || this.product?.item_massa || 0
    },

    actionButton() {
      let text =
        this.cartRow !== undefined
          ? `Обновить | ${this.currentPrice} ${this.$store.getters['app/currency']}`
          : `В корзину | ${this.currentPrice} ${this.$store.getters['app/currency']}`

      if (this.qty > 1) {
        text += ` x ${this.qty}`
      }

      return {
        active: true,
        action: this.notAvailable ? () => {} : this.addToCart,
        text: this.notAvailable ? 'Не доступен' : text,
        disabled: this.notAvailable,
      }
    },

    bju() {
      const { product } = this
      return {
        protein: product.item_protein,
        fats: product.item_fats,
        carbohydrates: product.item_carbohydrates,
        calories: product.item_calories,
      }
    },

    addons() {
      const groups = this.product.addon_item
      if (groups) {
        groups.forEach((group) => {
          if (group.sub_item && Array.isArray(group.sub_item)) {
            group.sub_item?.forEach((el, i) => {
              let cartQty = this.cartAddons[el.sub_item_id]
              this.$set(el, 'qty', cartQty || 0)

              if (
                group.multi_option == 'one' &&
                group.require_addons &&
                i == 0
              ) {
                this.$set(el, 'qty', 1)
              } else {
                if (group.multi_option == 'one' && cartQty) {
                  group.sub_item[0].qty = 0
                }
                this.$set(el, 'qty', cartQty || 0)
              }
            })
          }
        })
      }

      return groups
    },

    /* sectionID() {
      return this.$route.params.section;
    }, */

    prouductID() {
      return this.$route.params.product
    },

    product() {
      return this.getProductDetailsByID(this.prouductID) || {}
    },

    notAvailable() {
      return (
        !this.product.prices || Object.is(+this.product?.not_available || 1, 2)
      )
    },

    prices() {
      return this.product.prices && this.product.prices[this.priceIndex]
    },

    basePrice() {
      const prices = this.prices
      const price = prices && prices.price
      return Number(price) || 0
    },

    discount() {
      const prices = this.prices
      const discountPrice = prices && prices.discount_price
      return discountPrice ? this.basePrice - (Number(discountPrice) || 0) : 0
    },

    discountedPrice() {
      return this.basePrice - this.discount
    },

    currentPrice() {
      return this.$price.roundupPrice(this.discountedPrice + this.addonsPrice)
    },

    cartRow() {
      return this.$route.query.row
    },

    addonsPrice() {
      let total = 0
      const addons = this.addons
      if (addons) {
        addons?.forEach((group) => {
          group.sub_item?.forEach((add) => {
            const price = Number(add.price) || 0
            total += price * add.qty
          })
        })
      }
      return total
    },

    ...mapGetters('catalog', ['getProductDetailsByID']),
  },

  methods: {
    validateAddons() {
      if (!this.addons?.length) return true

      const requiredGroups = []
      const errors = []

      this.addons?.forEach((group) => {
        if (!Array.isArray(group?.sub_item)) {
          return
        }

        let groupQty = 0

        group.sub_item?.forEach((item) => {
          groupQty += item.qty
        })

        if (
          group.multi_option_min_val &&
          group.multi_option_min_val > groupQty
        ) {
          errors.push(
            `"${group.subcat_name}" нужно выбрать минимум ${group.multi_option_min_val} шт.`
          )
        }

        if (group.multi_option_val && group.multi_option_val < groupQty) {
          errors.push(
            `"${group.subcat_name}" максимальное кол-во ${group.multi_option_val} шт.`
          )
        }

        if (
          group.require_addons === '2' &&
          !group.sub_item.find((item) => item.qty > 0)
        ) {
          requiredGroups.push(group.subcat_name)
        }
      })

      if (requiredGroups.length) {
        this.$store.commit('snackbar/update', {
          active: true,
          text: `Нужно выбрать "${requiredGroups.join(', ')}"`,
        })
        return false
      }

      if (errors.length) {
        this.$store.commit('snackbar/update', {
          active: true,
          text: errors.join(', '),
        })
        return false
      }

      return true
    },

    async addToCart() {
      if (!this.validateAddons()) {
        return
      }

      const { commit, dispatch } = this.$store
      commit('actionButton/update', { loading: true })

      const price = this.basePrice
      const size = this.prices.size
      const sizeID = this.prices.size_id

      let priceParams = [price]
      if (size) priceParams.push(size)
      if (sizeID) priceParams.push(sizeID)

      const sub_item = {}
      const addon_qty = {}

      if (this.addons) {
        this.addons?.forEach((group) => {
          const groupID = group.subcat_id
          group.sub_item?.forEach((add) => {
            if (add.qty > 0) {
              sub_item[groupID] = sub_item[groupID] || []
              addon_qty[groupID] = addon_qty[groupID] || []
              const value = `${add.sub_item_id}|${add.price}|${add.sub_item_name}`
              sub_item[groupID].push(value)
              addon_qty[groupID].push(add.qty * this.qty)
            }
          })
        })
      }

      const params = {
        // category_id: this.sectionID,
        item_id: this.product.item_id,
        qty: this.qty,
        price: priceParams.join('|'),
        sub_item,
        addon_qty,
        row: this.cartRow,
        from: this.$route.query?.from,
      }

      const result = await dispatch('checkout/addItem', params)
      this.$emitter.emit('product-add')

      // this.$tracardi.track('product-added-to-basket', {
      //   id: this.product.item_id,
      //   name: this.product.item_name,
      //   price: this.currentPrice,
      //   quantity: this.qty,
      //   ...(this.prices?.size && {
      //     variant: {
      //       name: this.prices.size,
      //     },
      //   }),
      // })

      commit('actionButton/update', { loading: false })

      let message
      if (result.code == 1) {
        message =
          this.cartRow !== undefined
            ? `Обновлено: ${this.product.item_name}`
            : `Добавлено: ${this.product.item_name}`
      } else {
        message = result.msg || 'Ошибка при добавлении в корзину'
      }
      commit('snackbar/update', { active: true, text: message })

      this.$router.go(-1)
    },

    changeVariant(i) {
      this.priceIndex = i
    },

    minus() {
      if (this.qty > 1) {
        this.qty--
      }
    },

    plus() {
      this.qty++
    },

    setCartData() {
      const cartData = this.product && this.product.cart_data
      if (!cartData) return

      this.qty = cartData.qty

      for (const key in cartData.sub_item) {
        const addons = cartData.sub_item[key]

        addons.forEach((add, i) => {
          const id = add.split('|')[0]
          const qty = cartData.addon_qty[key][i] / this.qty
          this.$set(this.cartAddons, id, qty)
        })
      }

      const price = cartData.price
      const variantID = price.split('|')[2]
      let variantIndex
      if (variantID) {
        variantIndex = this.product.prices.findIndex((item) => {
          return item['size_id'] == variantID
        })
      }
      if (variantIndex) {
        this.priceIndex = variantIndex
      }
    },

    ...mapActions('catalog', ['loadProductDetails']),
  },

  async created() {
    await this.loadProductDetails({
      id: this.prouductID,
      row: this.cartRow,
    })
    this.loading = false
    this.setCartData()

    const product = {
      id: this.product.item_id,
      name: this.product.item_name,
      price: this.currentPrice,
    }

    if (this.prices.size) {
      product.variant = this.prices.size
    }

    this.$emitter.emit('productDetails', product)
  },

  async mounted() {
    await this.$nextTick()
    const actionBtn = document.querySelector('.action-btn-wrapper')
    if (actionBtn) {
      actionBtn.classList.remove('express-submit-cart')
    }
  },
}
</script>

<style lang="scss" scoped>
.qty-box {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  .qty-button {
    min-width: 72px;
  }
}

.photo {
  margin-bottom: -24px;
  // margin-top: -24px;
  // position: fixed;
  // top: 32px;
  // left: 50%;
  // height: 248px;
  // transform: translateX(-50%);
}

.name {
  margin-bottom: 16px;
  font-size: 21px;
  font-weight: 900;
}

.product-details {
  position: relative;
  z-index: 1;
  overflow: hidden;
  // margin-top: 200px;
  border-radius: 0;
  border-top-left-radius: $border-radius-root;
  border-top-right-radius: $border-radius-root;
  box-shadow: 0 -16px 16px rgba(0, 0, 0, 0.05);
  line-height: 1.2;
  padding-bottom: 100px;
}

.variant {
  font-weight: 600;
}

.old-price {
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 400;
}

.weight {
  font-size: 14px;
}
</style>
